import Vue from 'vue'

const actions = {
  RequestCandidate(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`home/request_candidates/${payload.candidate_id}`).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  GetCandidates({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get('home/get_candidates').then(response => {
        commit('GET_CANDIDATES_DATA', response.data.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  GetData({ commit, rootGetters, dispatch }) {
    Vue.prototype.$http.get(`persons${rootGetters['paginate/paginateUrl']}`).then(response => {
      commit('GET_PERSONS_DATA', response.data.data)
      dispatch('paginate/SetPaginate', response.data.paginate, { root: true })
    })
  },
  GetPersonData({ commit }, user) {
    Vue.prototype.$http.get(`persons/${user}`).then(response => {
      commit('GET_PERSON_DATA', response.data.person)
      let cv = {}
      if (response.data.person.cv) cv = response.data.person.cv
      commit('upload/SET_CV', cv, { root: true })
    })
  },
  add_experience(_, Data) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('home/cv/create_experience', Data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  add_occupation(_, Data) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('home/cv/create_occupation', Data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  add_interest(_, Data) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('home/cv/create_interest', Data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  add_education(_, Data) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('home/cv/create_education', Data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  add_volunteering_work(_, Data) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('home/cv/create_volunteering_work', Data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  add_language(_, Data) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('home/cv/create_language', Data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  delete_experience(_, experience) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.delete(`home/cv/delete_experience/${experience}`).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  delete_occupation(_, occupation) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.delete(`home/cv/delete_occupation/${occupation}`).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  delete_interest(_, interest) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.delete(`home/cv/delete_interest/${interest}`).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  delete_education(_, education) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.delete(`home/cv/delete_education/${education}`).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  delete_volunteering_work(_, volunteeringWork) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.delete(`home/cv/delete_volunteering_work/${volunteeringWork}`).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  delete_language(_, language) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.delete(`home/cv/delete_language/${language}`).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  update_person(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`person/update/${payload.user_id}`, payload.UserData).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  create(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('person/create', payload).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
}
export default actions
