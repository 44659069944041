export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      layout: 'horizontal',
      full_wave: true,
    },
  }, {
    path: '/jobs',
    name: 'jobs',
    component: () => import('@/views/home/Jobs.vue'),
    meta: {
      layout: 'horizontal',
      full_wave: false,
    },
  }, {
    path: '/candidates',
    name: 'candidates',
    component: () => import('@/views/home/candidates.vue'),
    meta: {
      layout: 'horizontal',
      full_wave: false,
    },
  },
]
