<template>
  <div>
    <div class="form-label-group">
      <b-form-input
        :id="'label_' + _uid"
        v-model="localValue"
        placeholder=" "
        :type="type"
        :state="errorCheck()"
      />
      <label
        class="lp"
        :for="'label_' + _uid"
      >{{ label }}</label>

      <div v-if="errors">
        <b-alert
          show
          variant="danger"
          fade
        >
          <ul>
            <li
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ $t(error) }}
            </li>
          </ul>
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VxInput',
  props: {
    label: {
      default: '',
      type: String,
    },
    type: {
      default: 'text',
      type: String,
    },
    value: {
      default: null,
    },
    errors: {
      default: null,
      type: Array,
    },
  },
  data() {
    return {
      localValue: '',
    }
  },
  watch: {
    localValue(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.localValue = val
    },
  },
  created() {
    this.localValue = this.value
  },
  methods: {
    errorCheck() {
      if (this.errors) return false
      return null
    },
  },
}
</script>

<style scoped>
  .lp {
    opacity: 0.7
  }
  .lp:focus {
    color:rgb(var(--vs-primary));
    opacity: 1;
  }
</style>
