import Vue from 'vue'

const actions = {
  GetData({ commit, rootGetters, dispatch }) {
    Vue.prototype.$http.get(`cashiers${rootGetters['paginate/paginateUrl']}`).then(response => {
      commit('GET_CASHIERS_DATA', response.data.data)
      dispatch('paginate/SetPaginate', response.data.paginate, { root: true })
    })
  },
  getCashier({ commit }, cashierId) {
    Vue.prototype.$http.get(`cashiers/${cashierId}`).then(response => {
      commit('GET_CASHIER_DATA', response.data.cashier)
    })
  },
  GetTrashedData({ commit }) {
    Vue.prototype.$http.get('cashiers/trashed').then(response => {
      commit('GET_TRASHED_CASHIERS_DATA', response.data.trashedCashiers)
    })
  },
  RestoreCashier({ dispatch }, cashierId) {
    Vue.prototype.$http.post(`restore_cashier/${cashierId}`).then(() => {
      dispatch('GetTrashedData')
    })
  },
  storeCashier(_, cashier) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('cashiers', cashier).then(response => {
        this.dispatch('cashier/GetData')
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateCashier(_, cashier) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.put(`cashiers/${cashier.id}`, cashier).then(response => {
        this.dispatch('cashier/GetData')
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  DeleteCashier({ dispatch }, CashierID) {
    Vue.prototype.$http.delete(`cashiers/${CashierID}`).then(() => {
      dispatch('GetData')
    })
  },
}
export default actions
