export default [
  {
    path: '/acp/users',
    name: 'User',
    component: () => import('@/views/dashboard/user/list.vue'),
    meta: {
      authRequired: 'true',
      layout: 'vertical',
      AnyPermissions: ['User Show', 'User ShowOwner'],
    },
  },
  {
    path: '/acp/user/create',
    name: 'CreateUser',
    component: () => import('@/views/dashboard/user/Create.vue'),
    meta: {
      permission: 'User Create',
      authRequired: 'true',
      layout: 'vertical',
    },
  },
  {
    path: '/acp/user/trashed',
    name: 'trashed_users',
    component: () => import('@/views/dashboard/user/Trashed.vue'),
    meta: {
      AnyPermissions: ['User ShowTrash', 'User ShowTrashOwner'],
      authRequired: 'true',
      layout: 'vertical',
    },
  },
  {
    path: '/acp/user/:userId/details',
    name: 'user_details',
    component: () => import('@/views/dashboard/user/Detail.vue'),
    meta: {
      AnyPermissions: ['User Details', 'User DetailsOwner'],
      authRequired: 'true',
      layout: 'vertical',
    },
  },
  {
    path: '/acp/user/:userId',
    name: 'EditUser',
    component: () => import('@/views/dashboard/user/Edit.vue'),
    meta: {
      AnyPermissions: ['User Edit', 'User EditOwner'],
      authRequired: 'true',
      layout: 'vertical',
    },
  },
]
