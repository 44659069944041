import Vue from 'vue'

const actions = {
  uploadImage(_, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('name', 'image')
      data.append('file', payload)

      Vue.prototype.$http.post('upload/image', data, {
        headers: {
          'Content-Type': 'image/png',
        },
      }).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getImage(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get(`upload/image/${payload.id}`)
        .then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
    })
  },
  resetImage({ commit }, payload) {
    commit('SET_IMAGE', payload.value)
  },
  uploadCv({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('name', 'pdf')
      data.append('file', payload.file)
      let url = 'upload/cv'
      if (payload.user_id) {
        url = `upload/cv/${payload.user_id}`
        Vue.prototype.$http.post(url, data, {
          headers: {
            'Content-Type': 'application/pdf',
          },
        }).then(response => {
          commit('SET_CV', response.data.Cv)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      }
    })
  },
  resetCv({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let url = 'upload/cv/delete'
      if (payload.user_id) url = `upload/cv/delete/${payload.user_id}`
      Vue.prototype.$http.post(url, {}).then(response => {
        commit('SET_CV', {})
        resolve(response)
      }).catch(error => {
        reject(error)
      })
      commit('SET_CV', {})
    })
  },
}
export default actions
