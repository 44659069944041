const mutations = {
  SET_FILTER(state, data) {
    state.filter = data
  },
  SET_PAGINATE(state, data) {
    state.total = data.total
    state.current_page = data.current_page
    state.firstItem = data.firstItem
    state.lastItem = data.lastItem
  },
  SET_PAGINATE_MODEL(state, model) {
    state.model = model
  },
  SET_PAGINATE_DEFAULT_BEFORE_SEARCH(state) {
    state.total = 0
    state.current_page = 1
    state.firstItem = 0
    state.lastItem = 0
    state.page = 1
  },
  SET_PAGINATE_DEFAULT(state) {
    state.total = 0
    state.current_page = 1
    state.firstItem = 0
    state.lastItem = 0
    state.page = 1
    state.take = 5
    state.search = null
    state.sort = null
    state.sortType = null
    state.filter = null
  },
  SET_PAGINATE_PAGE(state, page) {
    state.page = page
  },
  SET_PAGINATE_TAKE(state, take) {
    state.take = take
  },
  SET_PAGINATE_SEARCH(state, search) {
    state.search = search
  },
  SET_PAGINATE_SORT(state, data) {
    state.sort = data.sort
    state.sortType = data.sortType
  },
}
export default mutations
