<template>
  <div>
    <b-badge
      pill
      :variant="color"
      class="badge-glow"
    >
      <b-avatar
        v-if="icon"
        :variant="iconColorBg"
        size="sm"
      >
        <fa-icon
          :pack="pack"
          :color="iconColor"
          :icon="icon"
        />
      </b-avatar>
      <slot />
    </b-badge>
  </div>
</template>
<script>

export default {
  name: 'VxBadge',
  props: {
    color: {
      default: 'primary',
      type: String,
    },
    iconColorBg: {
      default: 'primary',
      type: String,
    },
    iconColor: {
      default: null,
      type: String,
    },
    pack: {
      default: 'fa',
      type: String,
    },
    icon: {
      default: null,
      type: String,
    },
  },
}
</script>
