const mutations = {
  GET_PERSONS_DATA(state, data) {
    state.persons = data
  },
  GET_PERSON_DATA(state, data) {
    state.person = data
  },
  GET_CANDIDATES_DATA(state, data) {
    state.candidates = data
  },
}
export default mutations
