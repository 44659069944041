const mutations = {
  GET_USERS_DATA(state, data) {
    state.users = data
  },
  GET_USER_DATA(state, data) {
    state.user = data
  },
  GET_TRASHED_USERS_DATA(state, data) {
    state.trashedUsers = data
  },
  GET_USERS_NAMES(state, data) {
    state.usersNames = data
  },
  GET_USER_TRANSACTION_DATA(state, data) {
    state.transactions = data
  },
}
export default mutations
