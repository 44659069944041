import Vue from 'vue'

const actions = {
  GetData({
    commit, rootGetters, dispatch,
  }) {
    Vue.prototype.$http.get(`categories${rootGetters['paginate/paginateUrl']}`).then(response => {
      commit('GET_CATS_DATA', response.data.data)
      dispatch('paginate/SetPaginate', response.data.paginate, { root: true })
    })
  },
  GetHomeData({
    commit,
  }) {
    Vue.prototype.$http.get('home/categories').then(response => {
      commit('GET_CATS_DATA', response.data.data)
    })
  },
  GetTrashedData({ commit }) {
    Vue.prototype.$http.get('categories/trashed').then(response => {
      commit('SET_TRASHED_DATA', response.data.data)
    })
  },
  restore({ dispatch }, payload) {
    Vue.prototype.$http.post(`categories/restore/${payload.id}`).then(() => {
      dispatch('GetTrashedData')
    })
  },
  GetCat({ commit }, CatId) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get(`categories/${CatId}`).then(response => {
        commit('GET_CAT_DATA', response.data.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  Store({ dispatch }, CatData) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('categories', CatData).then(response => {
        dispatch('GetData')
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  Update({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.put(`categories/${payload.id}`, payload.Data).then(response => {
        dispatch('GetData')
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  Delete({ dispatch }, payload) {
    Vue.prototype.$http.delete(`categories/${payload.id}`).then(() => {
      dispatch('GetData')
    })
  },
}
export default actions
