const mutations = {
  GET_CATS_DATA(state, data) {
    state.cats = data
  },
  GET_CAT_DATA(state, data) {
    state.cat = data
  },
  SET_TRASHED_DATA(state, data) {
    state.trashedData = data
  },

}
export default mutations
