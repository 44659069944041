const getters = {
  generalSettings: state => {
    let settings = []
    state.settings.forEach(setting => {
      if (['APP_NAME', 'APP_ENV', 'APP_DEBUG', 'APP_URL', 'FRONT_APP_URL', 'LOG_CHANNEL', 'LOG_LEVEL'].includes(setting.key)) {
        settings.push(setting)
      }
    })
    settings = { title: 'General', icon: 'sliders-h', settings }
    return settings
  },
  databaseSettings: state => {
    let settings = []
    state.settings.forEach(setting => {
      if (['DB_CONNECTION', 'DB_HOST', 'DB_PORT', 'DB_DATABASE', 'DB_USERNAME', 'DB_PASSWORD'].includes(setting.key)) {
        settings.push(setting)
      }
    })
    settings = { title: 'Database', icon: 'database', settings }
    return settings
  },
  mailSettings: state => {
    let settings = []
    state.settings.forEach(setting => {
      if (['MAIL_HOST', 'MAIL_PORT', 'MAIL_USERNAME', 'MAIL_PASSWORD', 'MAIL_ENCRYPTION', 'MAIL_FROM_ADDRESS', 'MAIL_FROM_NAME'].includes(setting.key)) {
        settings.push(setting)
      }
    })
    settings = { title: 'Mail', icon: 'paper-plane', settings }
    return settings
  },
  pusherSettings: state => {
    let settings = []
    state.settings.forEach(setting => {
      if (['PUSHER_APP_ID', 'PUSHER_APP_KEY', 'PUSHER_APP_SECRET', 'PUSHER_APP_CLUSTER', 'MIX_PUSHER_APP_KEY', 'MIX_PUSHER_APP_CLUSTER'].includes(setting.key)) {
        settings.push(setting)
      }
    })
    settings = { title: 'Pusher', icon: 'satellite-dish', settings }
    return settings
  },
  contactSettings: state => {
    let settings = []
    state.settings.forEach(setting => {
      if (['CONTACT_MAIL', 'CONTACT_PHONE', 'CONTACT_FACEBOOK', 'CONTACT_INSTAGRAM', 'CONTACT_WHATSAPP', 'CONTACT_TELEGRAM', 'CONTACT_TWITTER', 'CONTACT_MESSENGER', 'CONTACT_YOUTUBE', 'CONTACT_GOOGLE', 'CONTACT_LINKEDIN'].includes(setting.key)) {
        settings.push(setting)
      }
    })
    settings = { title: 'Contact', icon: 'envelope', settings }
    return settings
  },
  loginSettings: state => {
    let temp = []
    const settings = []
    let valueCheck = false
    state.settings.forEach(setting => {
      if (['GITHUB_CLIENT_ID', 'GITHUB_CLIENT_SECRET'].includes(setting.key)) {
        temp.push(setting)
      }
    })
    settings.push({ title: 'github', settings: temp })
    valueCheck = !!(settings[settings.length - 1].settings[0].value || settings[settings.length - 1].settings[1].value)
    settings[settings.length - 1].switch = valueCheck
    temp = []
    state.settings.forEach(setting => {
      if (['GITLAB_CLIENT_ID', 'GITLAB_CLIENT_SECRET'].includes(setting.key)) {
        temp.push(setting)
      }
    })
    settings.push({ title: 'gitlab', settings: temp })
    valueCheck = !!(settings[settings.length - 1].settings[0].value || settings[settings.length - 1].settings[1].value)
    settings[settings.length - 1].switch = valueCheck
    temp = []
    state.settings.forEach(setting => {
      if (['GOOGLE_CLIENT_ID', 'GOOGLE_CLIENT_SECRET'].includes(setting.key)) {
        temp.push(setting)
      }
    })
    settings.push({ title: 'google', settings: temp })
    valueCheck = !!(settings[settings.length - 1].settings[0].value || settings[settings.length - 1].settings[1].value)
    settings[settings.length - 1].switch = valueCheck
    temp = []
    state.settings.forEach(setting => {
      if (['FACEBOOK_CLIENT_ID', 'FACEBOOK_CLIENT_SECRET'].includes(setting.key)) {
        temp.push(setting)
      }
    })
    settings.push({ title: 'facebook', settings: temp })
    valueCheck = !!(settings[settings.length - 1].settings[0].value || settings[settings.length - 1].settings[1].value)
    settings[settings.length - 1].switch = valueCheck
    temp = []
    state.settings.forEach(setting => {
      if (['LINKEDIN_CLIENT_ID', 'LINKEDIN_CLIENT_SECRET'].includes(setting.key)) {
        temp.push(setting)
      }
    })
    settings.push({ title: 'linkedin', settings: temp })
    valueCheck = !!(settings[settings.length - 1].settings[0].value || settings[settings.length - 1].settings[1].value)
    settings[settings.length - 1].switch = valueCheck
    temp = []
    state.settings.forEach(setting => {
      if (['TWITTER_CLIENT_ID', 'TWITTER_CLIENT_SECRET'].includes(setting.key)) {
        temp.push(setting)
      }
    })
    settings.push({ title: 'twitter', settings: temp })
    valueCheck = !!(settings[settings.length - 1].settings[0].value || settings[settings.length - 1].settings[1].value)
    settings[settings.length - 1].switch = valueCheck
    temp = []
    state.settings.forEach(setting => {
      if (['MICROSOFT_CLIENT_ID', 'MICROSOFT_CLIENT_SECRET'].includes(setting.key)) {
        temp.push(setting)
      }
    })
    settings.push({ title: 'microsoft', settings: temp })
    valueCheck = !!(settings[settings.length - 1].settings[0].value || settings[settings.length - 1].settings[1].value)
    settings[settings.length - 1].switch = valueCheck
    temp = []
    return settings
  },
  siteSettings: state => {
    let settings = []
    state.settings.forEach(setting => {
      if (['SITE_LOGO', 'SLIDER_TEXT', 'SITE_TITLE', 'MAP_AMPLITUDE', 'MAP_LONGITUDE'].includes(setting.key)) {
        settings.push(setting)
      }
    })
    settings = { title: 'Site', icon: 'home', settings }
    return settings
  },

}
export default getters
