import Vue from 'vue'

// axios
import axios from 'axios'
import router from '@/router'
import store from '../store'

let RequestCount = 0
let domain = `${window.location.origin}`
if (window.location.host === 'localhost:8080') domain = 'http://localhost:8000'
const axiosIns = axios.create({
  baseURL: `${domain}/api`,
})
axiosIns.defaults.withCredentials = true

axiosIns.interceptors.request.use(config => {
  RequestCount += 1
  const axiosInsConfig = config
  if (localStorage.getItem('access_token') && localStorage.getItem('user')) {
    axiosInsConfig.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`
    axiosInsConfig.headers.common.Accept = `application/json; charset=utf-8; boundary=${Math.random().toString().substr(2)}`
  }
  if (Vue.prototype.$FingerprintJS) axiosInsConfig.headers.common.fingerprint = Vue.prototype.$FingerprintJS
  store.dispatch('app/update_loading', true)
  axiosInsConfig.headers.common.LOCAL = Vue.prototype.$trans.locale
  return config
}, error => Promise.reject(error))

axiosIns.interceptors.response.use(response => {
  RequestCount -= 1
  if (RequestCount === 0) store.dispatch('app/update_loading', false)
  if (response.data.message) {
    Vue.prototype.$swal({
      icon: 'success',
      title: Vue.prototype.$trans.t('Success'),
      text: Vue.prototype.$trans.t(response.data.message),
      showConfirmButton: false,
      allowOutsideClick: true,
    })
  }
  return response
}, error => {
  RequestCount -= 1
  if (RequestCount === 0) store.dispatch('app/update_loading', false)
  let MessageError
  if (error.response.data) {
    if (error.response.data.message) MessageError = error.response.data.message
  } else MessageError = Vue.prototype.$trans.t('Unexpected Error')

  if (error.response.status === 422) {
    Vue.prototype.$swal({
      icon: 'error',
      title: Vue.prototype.$trans.t('Error!'),
      text: Vue.prototype.$trans.t(MessageError),
      showConfirmButton: false,
      allowOutsideClick: true,
    })
  } else if (error.response.status === 400) {
    router.push({
      name: 'error-404',
    })
  } else if (error.response.status === 403) {
    store.dispatch('auth/Logout', true)
  } else if (error.response.status === 401) {
    store.dispatch('auth/Logout', true)
  } else if (error.response.status === 404) {
    router.push({
      name: 'error-404',
    })
  } else if (error.response.status === 405) {
    router.push({
      name: 'error-404',
    })
  } else if (error.response.status === 500) {
    // router.push({
    //   name: 'error-500',
    // })
  }
  return Promise.reject(error)
})
export default axiosIns
