const mutations = {
  GET_JOB(state, data) {
    state.job = data
  },
  GET_COMPANIES_DATA(state, data) {
    state.companies = data
  },
  GET_COMPANY_DATA(state, data) {
    state.company = data
  },
  GET_GLOBAL_JOBS(state, data) {
    state.global_jobs = data
  },
}
export default mutations
