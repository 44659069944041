/* eslint-disable */
import {
  BootstrapVue, IconsPlugin, ToastPlugin, ModalPlugin,
} from 'bootstrap-vue'
import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import Vuesax from 'vuesax'
import i18n from '@/libs/i18n'
import VueI18n from 'vue-i18n'
import axios from './libs/axios'
import router from './router'
import store from './store'
import App from './App.vue'
import auth from './store/auth'
import './global-components'
import '@themeConfig'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import 'vue-toastification/dist/index.css'
import 'material-icons/iconfont/material-icons.css'
import '../vuesaxConfig.js'
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import FingerprintJS from './libs/FingerprintJS.js'
import VueMeta from 'vue-meta'
require('@core/scss/core.scss')
require('@/assets/scss/style.scss')
require('@/assets/scss/vuesax.scss')
require('@/assets/scss/MyStyle.css')

Vue.use(VueMeta)
Vue.use(Vuesax)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCompositionAPI)
Vue.use(VueI18n)

Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.$trans = i18n
Vue.prototype.$auth = auth.getters
Vue.prototype.$authAction = auth.actions
Vue.prototype.$fullImagePath = full_image_path

Vue.mixin({
  computed: {
    EnvRegister() {
      return store.getters['app/register']
    }, EnvTransaction() {
      return store.getters['app/transaction']
    }, EnvCashier() {
      return store.getters['app/cashier']
    },
  },
})
const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
global.vm = app

function full_image_path(url) {
  if(url) return `http://localhost:8000/uploads/${url}`;
}

