import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { quillEditor } from 'vue-quill-editor'
import VxTooltip from './views/components/VxTooltip.vue'
import VsPaginationTable from './views/components/VsPaginationTable.vue'
import FaIcon from './views/components/FaIcon.vue'
import VxInput from './views/components/form/VxInput.vue'
import VxTextarea from './views/components/form/VxTextarea.vue'
import VxBadge from './views/components/VxBadge.vue'
import VxUpload from './views/components/form/VxUpload.vue'
import HtFooter from './views/home/component/HtFooter.vue'

library.add(fas)
library.add(fab)
library.add(far)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('vuexy-logo', VuexyLogo)

Vue.component(VxTooltip.name, VxTooltip)
Vue.component(quillEditor.name, quillEditor)
Vue.component(HtFooter.name, HtFooter)
Vue.config.productionTip = false
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(VsPaginationTable.name, VsPaginationTable)
Vue.component(FaIcon.name, FaIcon)
Vue.component(VxInput.name, VxInput)
Vue.component(VxTextarea.name, VxTextarea)
Vue.component(VxBadge.name, VxBadge)
Vue.component(VxUpload.name, VxUpload)
