const mutations = {
  GET_USER_PROFILE(state, data) {
    state.userProfile = data
  },
  GET_FEATURES(state, data) {
    state.features = data
  },
  GET_SERVICES(state, data) {
    state.services = data
  },
}
export default mutations
