import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './home'
import Auth from './auth'
import Dashboard from './dashboard'
import errorsRoute from './errors'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [

    ...Home,
    ...Auth,
    ...Dashboard,
    ...errorsRoute,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.meta.authRequired) {
    if (!Vue.prototype.$auth.GetAuth()) {
      return next({ name: 'login' })
    } if (!Vue.prototype.$auth.HasRoleAndPermission(to.meta)) {
      return next({ name: 'not-authorized' })
    }
    return next()
  }
  return next()
})
export default router
