const state = {
  plans: [],
  plan: {},
  posts: [],
  post: {},
  currentPage: 1,
  perPage: 9,
  rows: 0,
}
export default state
