import Vue from 'vue'

const actions = {
  UpdateProfile(_, userData) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('home/update_profile', userData).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  GetUserProfile({ commit }) {
    Vue.prototype.$http.get('home/profile').then(response => {
      commit('GET_USER_PROFILE', response.data.profile)
      let cv = {}
      if (response.data.profile.cv) cv = response.data.profile.cv
      commit('upload/SET_CV', cv, { root: true })
    })
  },
  GetFeatures({ commit }) {
    Vue.prototype.$http.get('home/features').then(response => {
      commit('GET_FEATURES', response.data.features)
    })
  },
  GetServices({ commit }) {
    Vue.prototype.$http.get('home/services').then(response => {
      commit('GET_SERVICES', response.data.services)
    })
  },
  DeleteDevice({ dispatch }, deviceID) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.delete(`home/device_delete/${deviceID}`).then(response => {
        dispatch('GetUserProfile')
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  DeleteSubscribe({ dispatch }, subscribeID) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.delete(`home/subscribe_delete/${subscribeID}`).then(response => {
        dispatch('GetUserProfile')
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  CreatePost(_, PostData) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('home/create-post', PostData).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  AuthorizeDevice({ dispatch }, deviceId) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`home/authorize-device/${deviceId}`).then(response => {
        dispatch('GetUserProfile')
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
}
export default actions
