const getters = {
  GetAuth: () => {
    if (JSON.parse(localStorage.getItem('user'))) return JSON.parse(localStorage.getItem('user'))
    return false
  },
  GetAuthAvatar: () => {
    if (JSON.parse(localStorage.getItem('user'))) return JSON.parse(localStorage.getItem('user')).avatar_url
    return false
  },
  name: () => {
    let name = 'Not Login'
    if (getters.GetAuth()) {
      name = getters.GetAuth().name
    }
    return name
  },
  roles: () => {
    let roles = []
    if (getters.GetAuth()) {
      roles = getters.GetAuth().role
    }
    return roles
  },
  permissions: () => {
    let permissions = []
    if (getters.GetAuth()) {
      permissions = getters.GetAuth().permissions
    }
    return permissions
  },
  HasRoleAndPermission: Requirement => {
    if (!Requirement) {
      return true
    }
    let userRoles = []
    let userPermissions = []
    userRoles = getters.roles()
    userPermissions = getters.permissions()
    if (userRoles.includes('SuperAdmin')) {
      return true
    }
    let AccessGeneratorAnyRole = false
    let AccessGeneratorPermissionRole = false
    let AccessGeneratorAll = true
    let AllPermissions = []
    let AnyPermissions = []
    let AllRoles = []
    let AnyRoles = []
    if (Requirement.AnyRoles) {
      AnyRoles = Requirement.AnyRoles
    }
    if (Requirement.AllRoles) {
      AllRoles = Requirement.AllRoles
    }
    if (Requirement.role) {
      AllRoles.push(Requirement.role)
    }
    if (Requirement.AnyPermissions) {
      AnyPermissions = Requirement.AnyPermissions
    }
    if (Requirement.AllPermissions) {
      AllPermissions = Requirement.AllPermissions
    }
    if (Requirement.permission) {
      AllPermissions.push(Requirement.permission)
    }
    if (AllPermissions.length === 0 && AllRoles.length === 0 && AnyPermissions.length === 0 && AnyRoles.length === 0) {
      return true
    }
    AllRoles.forEach(role => {
      if (!userRoles.includes(role)) {
        AccessGeneratorAll = false
      }
    })
    AllPermissions.forEach(permission => {
      if (!userPermissions.includes(permission)) {
        AccessGeneratorAll = false
      }
    })
    AnyRoles.forEach(role => {
      if (userRoles.includes(role)) {
        AccessGeneratorAnyRole = true
      }
    })
    if (AnyRoles.length === 0) AccessGeneratorAnyRole = true
    AnyPermissions.forEach(permission => {
      if (userPermissions.includes(permission)) {
        AccessGeneratorPermissionRole = true
      }
    })
    if (AnyPermissions.length === 0) AccessGeneratorPermissionRole = true

    if (AccessGeneratorAnyRole && AccessGeneratorPermissionRole && AccessGeneratorAll) {
      return true
    }
    return false
  },
}
export default getters
