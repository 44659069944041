const getters = {
  plans: state => state.plans,
  plan: state => state.plan,
  posts: state => state.posts,
  post: state => state.post,
  currentPage: state => state.currentPage,
  perPage: state => state.perPage,
  rows: state => state.rows,
}
export default getters
