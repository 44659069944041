const actions = {
  getData({ commit }) {
    global.vm.$http.get('settings').then(response => {
      commit('SET_SETTINGS', response.data.settings)
    })
  },
  saveSettings({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      global.vm.$http.put('settings', payload.settings).then(response => {
        dispatch('getData')
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  saveLoginSettings({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      global.vm.$http.put('settings/loginSettings', payload.settings).then(response => {
        dispatch('getData')
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
}
export default actions
