export default [
  {
    path: '/acp/roles',
    name: 'Role',
    component: () => import('@/views/dashboard/role/Roles.vue'),
    meta: {
      role: 'SuperAdmin',
      authRequired: 'true',
      layout: 'vertical',
    },
  }, {
    path: 'trashed',
    name: 'trashed_roles',
    component: () => import('@/views/dashboard/role/TrashedRoles.vue'),
    meta: {
      role: 'SuperAdmin',
      authRequired: 'true',
      layout: 'vertical',
    },
  },
]
