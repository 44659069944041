<template>
  <b-container fluid>
    <b-row
      class="mt-0 pt-0 pb-4"
      style="background: linear-gradient(-29deg, #FAA70A, #1565C0) !important;"
    >
      <b-col
        class="p-0 m-0"
        cols="12"
      >
        <ht-footer-wave />
      </b-col>
      <b-col
        class="mt-3"
        cols="12"
      >
        <b-row>
          <b-col
            xs="12"
            sm="6"
            md="6"
            lg="3"
            xl="3"
            class="text-center"
          >
            <b><h1>{{ $t('More') }}</h1>
            </b>
            <a @click="$router.push({name:'home'})">

              {{ $t('Home') }}
            </a>
            <br>
            <a @click="$router.push({name:'jobs'})">
              {{ $t('Jobs') }}
            </a>
            <br>
            <a @click="$router.push({name:'candidates'})">
              {{ $t('Employee') }}
            </a><br>
          </b-col>
          <b-col
            xs="12"
            sm="6"
            md="6"
            lg="3"
            xl="3"
            class="text-center"
          >
            <b><h1>{{ $t('ContactUs') }}</h1></b><br>
            <h4>
              <a
                class="m-2"
                href="https://www.google.com/maps/place/38+Newhaven+Bay,+Bremore,+Balbriggan,+Co.+Dublin,+K32+W968,+Ireland/data=!4m2!3m1!1s0x486722d6959a8a49:0x55bc945422f2056?sa=X&ved=2ahUKEwjKirGV0pn1AhVHSvEDHVIJBQwQ8gF6BAgFEAE"
              >
                <fa-icon
                  pack="fas"
                  icon="map"
                />
                38 Newhaven Bay Balbriggan Co Dublin
              </a>
              <br>
              <a
                class="m-2"
                :href="'mailto:' + siteContactInfo.mail"
              >
                <fa-icon
                  pack="fas"
                  icon="envelope"
                />
                {{ siteContactInfo.mail }}
              </a>
              <br>
              <a :href="'tel:' + siteContactInfo.phone">
                <fa-icon
                  pack="fas"
                  icon="phone"
                />
                {{ siteContactInfo.phone }}
              </a>
            </h4>
            <br>
          </b-col>
          <b-col
            xs="12"
            sm="6"
            md="6"
            lg="3"
            xl="3"
            class="text-center"
          >
            <b><h1>{{ $t('Follow us on our social medias') }}</h1></b><br>
            <b-row>
              <b-col class="footer-brands">
                <a :href="siteContactInfo.facebook">
                  <fa-icon
                    pack="fab"
                    icon="facebook"
                  />
                </a>
                <a
                  class="hidden"
                  :href="siteContactInfo.instagram"
                >
                  <fa-icon
                    pack="fab"
                    icon="instagram"
                  />
                </a>
                <a
                  class="hidden"
                  :href="'https://api.whatsapp.com/send?phone=' + siteContactInfo.whatsapp"
                >
                  <fa-icon
                    pack="fab"
                    icon="whatsapp"
                  />
                </a>
                <a
                  class="hidden"
                  :href="'https://web.telegram.org/#/im?p=' + siteContactInfo.telegram"
                >
                  <fa-icon
                    pack="fab"
                    icon="telegram"
                  />
                </a>
                <a :href="siteContactInfo.twitter">
                  <fa-icon
                    pack="fab"
                    icon="twitter"
                  />
                </a>
                <a
                  class="hidden"
                  :href="'https://msng.link/o/?'+ siteContactInfo.messenger + '=fm'"
                >
                  <fa-icon
                    pack="fab"
                    icon="facebook-messenger"
                  />
                </a>
                <a
                  class="hidden"
                  :href="siteContactInfo.youtube"
                >
                  <fa-icon
                    pack="fab"
                    icon="youtube"
                  />
                </a>
                <a
                  class="hidden"
                  :href="'mailto:' + siteContactInfo.google"
                >
                  <fa-icon
                    pack="fab"
                    icon="google"
                  />
                </a>
                <a
                  :href="siteContactInfo.linkedin"
                >
                  <fa-icon
                    pack="fab"
                    icon="linkedin"
                  />
                </a>
              </b-col>
            </b-row>
            <br>
          </b-col>
          <b-col
            xs="12"
            sm="6"
            md="6"
            lg="3"
            xl="3"
            class="text-center"
          >
            <img
              width="40%"
              :src="require('@/assets/images/HT/darklogo.png')"
            >
          </b-col>
        </b-row>
      </b-col>

    </b-row>
    <b-row
      class="p-1"
      style="background-color: #000"
    >
      <b-col
        cols="12"
        class="text-center"
      >
        Copyright © {{ new Date().getFullYear() }} Employrefugees.Com All Rights Reserved.
      </b-col>
      <b-col
        cols="12"
        class="text-center"
      >
        <h5>{{ $t('Powered By') }} <a href="https://layouteam.com/">Layouteam.com</a></h5>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import HtFooterWave from '@/views/home/component/HtFooterWave.vue'

export default {
  name: 'HtFooter',
  components: { HtFooterWave },
  computed: {
    siteTitle() {
      return this.$store.getters['app/title']
    },
    siteContactInfo() {
      return this.$store.getters['app/contactInfo']
    },
  },
}
</script>
<style>
.footer-brands svg {
  margin: 5px;
}
</style>
