import Vue from 'vue'

const actions = {
  getCashierTransactions({ commit }, payload) {
    commit('GET_CASHIER_TRANSACTIONS')
    Vue.prototype.$http.get(`cashier/transactions/${payload.id}`).then(response => {
      commit('SET_CASHIER_TRANSACTIONS', response.data.data)
    })
  },
  getUsers({ commit }) {
    Vue.prototype.$http.get('cashier/users').then(response => {
      commit('SET_USERS', response.data.data)
    })
  },
  getTransactionCategories({ commit }) {
    Vue.prototype.$http.get('cashier/transactions_categories').then(response => {
      commit('SET_TRANSACTION_CATEGORIES', response.data.data)
    })
  },
  getAccountTransaction({ commit }) {
    Vue.prototype.$http.get('account/').then(response => {
      commit('ACCOUNT_TRANSACTIONS', response.data.data)
    })
  },
  storeTransaction(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`cashier/newTransaction/${payload.id}`, payload.data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
}
export default actions
