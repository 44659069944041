const state = {
  users: [],
  user: {},
  usersNames: {},
  trashedUsers: [],
  transactions: [],
  users_paginate: {
    total: 0,
    current_page: 1,
    firstItem: 0,
    lastItem: 0,
  },
}
export default state
