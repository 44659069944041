<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >

    <component :is="layout">
      <router-view />
    </component>
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-show="vxloading"
        class="vx-loading"
      >
        <b-spinner

          style="width: 5rem; height: 5rem;"
        />
      </div>
    </transition>
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
export default {
  name: 'Employrefugees',
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

  },
  metaInfo() {
    return {
      title: 'Employrefugees',
      meta: [
        {
          name: 'description',
          content: 'the first arab website to resolve mobile Android and IOS solutions',
        },
        { property: 'og:title', content: 'the first arab website to resolve mobile Android and IOS solutions' },
        { property: 'og:site_name', content: 'Employrefugees' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    registerss() {
      return this.$store.getters['app/register']
    },
    vxloading() {
      return this.$store.getters['app/loading']
    },
    layout() {
      if (this.$route.meta.layout === 'vertical') return 'layout-vertical'
      if (this.$route.meta.layout === 'horizontal') return 'layout-horizontal'
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
    if (localStorage.getItem('local')) {
      this.$i18n.locale = localStorage.getItem('local')
      if (this.$i18n.locale === 'ar') {
        this.$vs.rtl = true
        this.$store.dispatch('appConfig/toggle_rtl', true)
      } else {
        this.$store.dispatch('appConfig/toggle_rtl', false)
      }
    }
    // if (this.$auth.GetAuth()) this.$store.dispatch('auth/UpdateAuth')
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        const myobj = document.getElementById('main_app_loader')
        myobj.remove()
      }
    }
  },
  setup() {
    const { skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    // if (skin.value === 'dark') {
    //   document.body.classList.add('dark-layout')
    //   document.body.classList.add('theme-dark')
    // }
    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })
    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })
    return {
      skinClasses,
    }
  },
  created() {
    this.$store.dispatch('app/get_site_data')
  },
}
</script>
