/*= ========================================================================================
  File Name: themeConfig.js
  Description: Theme configuration
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: hhttp://www.themeforest.net/user/pixinvent
========================================================================================== */

// MAIN COLORS - VUESAX THEME COLORS
import Vue from 'vue'
import Vuesax from 'vuesax'

export const colors = {
  primary: '#FAA70A',
  secondary: '#82868b',
  success: '#28C76F',
  danger: '#EA5455',
  warning: '#FF9F43',
  info: '#00cfe8',
}

// CONFIGS
const themeConfig = {
  rtl: false, // options[Boolean] : true, false(default)
}
Vue.use(Vuesax, { theme: { colors }, rtl: themeConfig.rtl })

export default themeConfig
