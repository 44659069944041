/* eslint-disable */
const getters = {
  cashierTransactions: state =>{
    return state.cashierTransactions
  },
  users: state =>{
    return state.users
  },
  transactionCategories: state =>{
    return state.transactionCategories
  },
  accountTransactions: state =>{
    return state.accountTransactions
  },
}
export default getters
