const mutations = {
  SET_PLANS(state, plans) {
    state.plans = plans
  },
  SET_PLAN(state, plan) {
    state.plan = plan
  },
  SET_POSTS(state, posts) {
    state.posts = posts
  },
  SET_POSTS_PAGINATION(state, pagination) {
    state.currentPage = pagination.current_page
    state.perPage = pagination.per_page
    state.rows = pagination.total
  },
  CHANGE_PAGE(state, page) {
    state.currentPage = page
  },
  SET_POST(state, post) {
    state.post = post
  },
  UPDATE_POST_LIKES(state, likeCount) {
    state.post.likes_count = likeCount
  },
  UPDATE_COMMENT_LIKES(state, data) {
    state.post.comments.forEach(comment => {
      // eslint-disable-next-line no-param-reassign
      if (comment.id === data.commentId) comment.likes_count = data.likeCount
    })
  },
  UPDATE_REPLIES_LIKES(state, data) {
    state.post.comments.forEach(comment => {
      if (comment.id === data.parentId) {
        comment.comments.forEach(reply => {
          // eslint-disable-next-line no-param-reassign
          if (reply.id === data.replyId) {
            // eslint-disable-next-line no-param-reassign
            reply.likes_count = data.likeCount
          }
        })
      }
    })
  },
}
export default mutations
