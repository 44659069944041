// import Vue from "vue";

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
    },
    beforeEnter(to, _, next) {
      if (!localStorage.getItem('access_token')) next()// not auth
      else next({ name: 'home' })
    },
  },
  {
    path: '/register-email/:email/:link',
    name: 'register-email',
    component: () => import('@/views/auth/Register-email.vue'),
    meta: {
      layout: 'full',
    },
    beforeEnter(to, _, next) {
      // if (!Vue.prototype.$EnvRegister)next({ name: 'error-404' })
      if (!localStorage.getItem('access_token')) next()// not auth
      else next({ name: 'home' })
    },
  },
  {
    path: '/authorize/:provider/callback',
    name: 'authorize-provider-callback',
    component: () => import('@/views/auth/AuthorizeProviderCallback.vue'),
    meta: {
      layout: 'full',
    },
    beforeEnter(to, _, next) {
      if (!localStorage.getItem('access_token')) next()// not auth
      else next({ name: 'home' })
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/Register-v2.vue'),
    meta: {
      layout: 'full',
    },
    beforeEnter(to, _, next) {
      // if (!store.getters['app/register'])next({ name: 'error-404' })
      if (!localStorage.getItem('access_token')) next()// not auth
      else next({ name: 'home' })
    },
  },
  {
    path: '/forgot_password',
    name: 'auth-forgot-password-v2',
    component: () => import('@/views/auth/ForgotPassword-v2.vue'),
    meta: {
      layout: 'full',
    },
    beforeEnter(to, _, next) {
      if (!localStorage.getItem('access_token')) next()// not auth
      else next({ name: 'home' })
    },
  }, {
    path: '/reset_password/:email/:code',
    name: 'reset_password',
    component: () => import('@/views/auth/ResetPassword-v2.vue'),
    meta: {
      layout: 'full',
    },
    beforeEnter(to, _, next) {
      if (!localStorage.getItem('access_token')) next()// not auth
      else next({ name: 'home' })
    },
  }, {
    path: '/RegisterCode/:email',
    name: 'RegisterCode',
    component: () => import('@/views/auth/codeRegister.vue'),
    meta: {
      layout: 'full',
    },
    beforeEnter(to, _, next) {
      // if (!store.getters['app/register'])next({ name: 'error-404' })
      if (!localStorage.getItem('access_token')) next()// not auth
      else next({ name: 'home' })
    },
  },
  {
    path: '/confirm_code/:email',
    name: 'confirm_code',
    component: () => import('@/views/auth/ConfirmCode.vue'),
    meta: {
      layout: 'full',
    },
    beforeEnter(to, _, next) {
      // if (!store.getters['app/register'])next({ name: 'error-404' })
      if (!localStorage.getItem('access_token')) next()// not auth
      else next({ name: 'home' })
    },
  }, {
    path: '/profile',
    name: 'UserProfile',
    component: () => import('@/views/auth/profile.vue'),
    meta: {
      authRequired: 'true',
      layout: 'horizontal',
    },
  }, {
    path: '/devices',
    name: 'UserDevices',
    component: () => import('@/views/auth/devices.vue'),
    meta: {
      authRequired: 'true',
      layout: 'horizontal',
    },
  },
]
