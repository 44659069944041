const mutations = {
  UPDATE_USER_INFO(state, value) {
    if (value) {
      localStorage.setItem('user', JSON.stringify(value))
      state.user = value
    }
  },
  LOGOUT(state, payload) {
    state.user = payload
    localStorage.removeItem('user')
    localStorage.removeItem('access_token')
  },
}
export default mutations
