// import axios from 'axios'
import Vue from 'vue'

const actions = {
  GetData({ commit, rootGetters, dispatch }) {
    Vue.prototype.$http.get(`users${rootGetters['paginate/paginateUrl']}`).then(response => {
      commit('GET_USERS_DATA', response.data.data)
      dispatch('paginate/SetPaginate', response.data.paginate, { root: true })
    })
  },
  GetTrashedData({ commit }) {
    Vue.prototype.$http.get('users/trashed').then(response => {
      commit('GET_TRASHED_USERS_DATA', response.data.trashedUsers)
    })
  },
  RestoreUser({ dispatch }, userId) {
    Vue.prototype.$http.post(`restore_user/${userId}`).then(() => {
      dispatch('GetTrashedData')
    })
  },
  GetUserData({ commit }, userId) {
    Vue.prototype.$http.get(`users/${userId}`).then(response => {
      commit('GET_USER_DATA', response.data.user)
    })
  },
  EditUserData(_, userData) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.put(`users/${userData.id}`, userData).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  CreateUser({ dispatch }, userData) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('users', userData).then(response => {
        dispatch('GetData')
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  DeleteUser({ dispatch }, userId) {
    Vue.prototype.$http.delete(`users/${userId}`).then(() => {
      dispatch('GetData')
    })
  },
  ChangePassword({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`users/change_password/${payload.id}`, payload.password).then(response => {
        dispatch('GetData')
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  EditUserStatus(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`users/changeStatus/${payload.userdata.id}`, payload.userdata).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  ChangeRole({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`users/changeRole/${payload.userId}`, { roleID: payload.roleID }).then(response => {
        dispatch('GetData')
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getUsersNames({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get('users/usersNames').then(response => {
        commit('GET_USERS_NAMES', response.data.usersNames)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getUserTransaction({ commit }, UserID) {
    Vue.prototype.$http.get(`user/transactions/${UserID}`).then(response => {
      commit('GET_USER_TRANSACTION_DATA', response.data.data)
    })
  },
  CreateNewTransaction(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`user/newTransaction/${payload.userId}`, payload.transaction).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  TransferMoney(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`user/transferMoney/${payload.userId}`, payload.transaction).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
}
export default actions
