const mutations = {
  GET_CASHIERS_DATA(state, data) {
    state.cashiers = data
  },
  GET_CASHIER_DATA(state, data) {
    state.cashier = data
  },
  GET_TRASHED_CASHIERS_DATA(state, data) {
    state.trashedCashiers = data
  },
}
export default mutations
