export default [
  {
    path: '/acp/settings',
    name: 'Settings',
    component: () => import('@/views/dashboard/setting/Settings.vue'),
    meta: {

      role: 'SuperAdmin',
      authRequired: 'true',
      layout: 'vertical',
    },
  },
]
