const mutations = {
  initState(state) {
    state.rolePermissions = []
  },
  getRoles(state, roles) {
    state.roles = roles
  },

  getPermissions(state, permissions) {
    state.permissions = permissions
  },
  getGroupedPermissions(state, groupedPermissions) {
    state.groupedPermissions = groupedPermissions
  },
  getRolePermissions(state, rolePermissions) {
    state.rolePermissions = rolePermissions
  },
  deleteRole(state, roleId) {
    const roleToDelete = state.roles.find(role => role.id === roleId)
    state.roles.splice(state.roles.findIndex(role => JSON.stringify(roleToDelete) === JSON.stringify(role)), 1)
  },
  getTrashedRoles(state, trashedRoles) {
    state.trashedRoles = trashedRoles
  },
  getModels(state, models) {
    state.models = models
  },
}
export default mutations
