export default [
  {
    path: '/acp/companies',
    name: 'company',
    component: () => import('@/views/dashboard/company/list.vue'),
    meta: {
      authRequired: 'true',
      layout: 'vertical',
      AnyPermissions: ['User Show', 'User ShowOwner'],
    },
  },
  {
    path: '/acp/company/:userId/detail',
    name: 'company_details',
    component: () => import('@/views/dashboard/company/detail.vue'),
    meta: {
      authRequired: 'true',
      layout: 'vertical',
      AnyPermissions: ['User Show', 'User ShowOwner'],
    },
  },
]
