import Vue from 'vue'

const actions = {

  RequestJobs(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post(`home/request_candidates/${payload.job_id}`).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  GetGlobalJobs({ commit }, payload) {
    let input = ''
    if (payload.category_id && payload.search) input = `?category_id=${payload.category_id}&search=${payload.search}`
    else if (payload.category_id) input = `?category_id=${payload.category_id}`
    else if (payload.search) input = `?search=${payload.search}`
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get(`home/get_jobs${input}`).then(response => {
        commit('GET_GLOBAL_JOBS', response.data.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  GetData({ commit, rootGetters, dispatch }) {
    Vue.prototype.$http.get(`companies${rootGetters['paginate/paginateUrl']}`).then(response => {
      commit('GET_COMPANIES_DATA', response.data.data)
      dispatch('paginate/SetPaginate', response.data.paginate, { root: true })
    })
  },
  GetCompanyData({ commit }, user) {
    Vue.prototype.$http.get(`companies/${user}`).then(response => {
      commit('GET_COMPANY_DATA', response.data.company)
    })
  },
  create_job(_, Data) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('home/cv/create_job', Data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  update_job(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.put(`home/cv/update_job/${payload.job}`, payload.Data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  job({ commit }, job) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get(`home/cv/job/${job}`).then(response => {
        commit('GET_JOB', response.data.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  delete_job(_, job) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.delete(`home/cv/delete_job/${job}`).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
}
export default actions
