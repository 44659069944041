export default [
  {
    path: '/acp/cashiers',
    name: 'Cashier',
    component: () => import('@/views/dashboard/cashier/list.vue'),
    meta: {
      role: 'SuperAdmin',
      authRequired: 'true',
      layout: 'vertical',
    },
  },
  {
    path: '/acp/cashier/trashed',
    name: 'trashed_cashiers',
    component: () => import('@/views/dashboard/cashier/Trashed.vue'),
    meta: {
      authRequired: 'true',
      role: 'SuperAdmin',
      layout: 'vertical',
    },
  },
  {
    path: '/acp/cashiers/:cashierId/transactions',
    name: 'cashier-transactions',
    component: () => import('@/views/dashboard/cashier/transactions.vue'),
    meta: {
      role: 'SuperAdmin',
      authRequired: 'true',
      layout: 'vertical',
    },
  },
]
