import User from './user'
import Role from './role'
import Cashier from './cashier'
import Settings from './settings'
import Company from './company'
import Person from './person'
import Category from './category'

export default [
  {
    path: '/acp',
    name: 'dashboard',
    component: () => import('@/views/dashboard/home.vue'),
    meta: {
      layout: 'vertical',
      authRequired: true,
      role: 'Admin',
    },
  },
  ...User,
  ...Company,
  ...Category,
  ...Person,
  ...Role,
  ...Cashier,
  ...Settings,

]
