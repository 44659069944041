import { $themeBreakpoints, $themeConfig } from '@themeConfig'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    loading: false,
    title: '',
    logo: '',
    sliderText: '',
    mapAmp: '',
    mapLong: '',
    contact_mail: '',
    contact_phone: '',
    contact_facebook: '',
    contact_whatsapp: '',
    contact_instagram: '',
    contact_telegram: '',
    contact_twitter: '',
    contact_messenger: '',
    contact_youtube: '',
    contact_google: '',
    contact_linkedin: '',
    register: true,
    cashier: false,
    transaction: false,
  },
  getters: {
    loading: state => state.loading,
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    title: state => {
      if (state.title) return state.title
      return $themeConfig.app.appName
    },
    sliderText: state => {
      if (state.sliderText) return state.sliderText
      return 'the first Arab website to support mobile solutions'
    },
    logo: state => {
      if (state.logo) return state.logo
      return $themeConfig.app.appLogoImage
    },
    mapAmplitude: state => state.mapAmp,
    mapLongitude: state => state.mapLong,
    contactInfo: state => {
      const contactInfo = {}
      contactInfo.mail = state.contact_mail
      contactInfo.phone = state.contact_phone
      contactInfo.facebook = state.contact_facebook
      contactInfo.whatsapp = state.contact_whatsapp
      contactInfo.instagram = state.contact_instagram
      contactInfo.telegram = state.contact_telegram
      contactInfo.twitter = state.contact_twitter
      contactInfo.messenger = state.contact_messenger
      contactInfo.youtube = state.contact_youtube
      contactInfo.google = state.contact_google
      contactInfo.linkedin = state.contact_linkedin
      return contactInfo
    },
    register: state => state.register,
    cashier: state => state.cashier,
    transaction: state => state.transaction,
  },
  mutations: {
    UPDATE_LOADING(state, val) {
      state.loading = val
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_SITE_SETTINGS(state, siteSettings) {
      siteSettings.forEach(element => {
        if (element.key === 'SITE_LOGO') state.logo = element.value
        else if (element.key === 'SLIDER_TEXT') state.sliderText = element.value
        else if (element.key === 'SITE_TITLE') state.title = element.value
        else if (element.key === 'MAP_AMPLITUDE') state.mapAmp = element.value
        else if (element.key === 'MAP_LONGITUDE') state.mapLong = element.value
        else if (element.key === 'CONTACT_MAIL') state.contact_mail = element.value
        else if (element.key === 'CONTACT_PHONE') state.contact_phone = element.value
        else if (element.key === 'CONTACT_FACEBOOK') state.contact_facebook = element.value
        else if (element.key === 'CONTACT_WHATSAPP') state.contact_whatsapp = element.value
        else if (element.key === 'CONTACT_INSTAGRAM') state.contact_instagram = element.value
        else if (element.key === 'CONTACT_TELEGRAM') state.contact_telegram = element.value
        else if (element.key === 'CONTACT_TWITTER') state.contact_twitter = element.value
        else if (element.key === 'CONTACT_MESSENGER') state.contact_messenger = element.value
        else if (element.key === 'CONTACT_YOUTUBE') state.contact_youtube = element.value
        else if (element.key === 'CONTACT_GOOGLE') state.contact_google = element.value
        else if (element.key === 'CONTACT_LINKEDIN') state.contact_linkedin = element.value
        else if (element.key === 'register') state.register = element.value
        else if (element.key === 'cashier') state.cashier = element.value
        else if (element.key === 'transaction') state.transaction = element.value
      })
    },
  },
  actions: {
    update_loading({ commit }, payload) {
      commit('UPDATE_LOADING', payload)
    },
    get_site_data({ commit }) {
      Vue.prototype.$http.get('home/get_site_data').then(response => {
        commit('SET_SITE_SETTINGS', response.data.siteSettings)
      })
    },
  },
}
