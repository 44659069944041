import Vue from 'vue'
import Vuex from 'vuex'
// Modules
import auth from './auth'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import user from './user'
import role from './role'
import paginate from './paginate'
import cashier from './cashier/index'
import settings from './settings'
import transaction from './transaction'
import category from './category'
import upload from './upload'
import home from './home'
import frontend from './frontend'
import employee from './employee'
import company from './company'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  modules: {
    app,
    auth,
    appConfig,
    employee,
    company,
    verticalMenu,
    user,
    paginate,
    role,
    cashier,
    settings,
    transaction,
    category,
    upload,
    home,
    frontend,
  },
  strict: process.env.DEV,
})
