export default [
  {
    path: '/acp/persons',
    name: 'person',
    component: () => import('@/views/dashboard/person/list.vue'),
    meta: {
      authRequired: 'true',
      layout: 'vertical',
      AnyPermissions: ['User Show', 'User ShowOwner'],
    },
  },
  {
    path: '/acp/person/create',
    name: 'person_create',
    component: () => import('@/views/dashboard/person/Create.vue'),
    meta: {
      authRequired: 'true',
      layout: 'vertical',
    },
  },
  {
    path: '/acp/person/cv/:userId',
    name: 'person_cv',
    component: () => import('@/views/dashboard/person/cv.vue'),
    meta: {
      authRequired: 'true',
      layout: 'vertical',
    },
  },
  {
    path: '/acp/person/update_cv/:userId',
    name: 'update_cv',
    component: () => import('@/views/dashboard/person/updateCv.vue'),
    meta: {
      authRequired: 'true',
      layout: 'vertical',
    },
  },

]
