export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  }, {
    path: '/error-500',
    name: 'error-500',
    component: () => import('@/views/error/Error500.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/error/NotAuthorized.vue'),
    meta: {
      layout: 'full',
    },
    beforeEnter(to, _, next) {
      if (!localStorage.getItem('access_token')) next()// not auth
      else next({ name: 'home' })
    },
  },
]
