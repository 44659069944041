<template>
  <div>
    <div class="form-label-group">
      <b-media class="mb-2">
        <template class="m-auto">
          <transition
            name="fade"
            mode="out-in"
          >
            <div
              :class="!image.id?'UploadImageBorder':''"
              class="d-flex UploadImageButton"
              @click="$refs.refInputEl.click()"
            >
              <input
                ref="refInputEl"
                accept="image/*"
                type="file"
                name="file"
                class="d-none"
                @change="uploadImage($event)"
              >
              <b-avatar
                v-if="image.id"
                class="m-auto"
                :src="$fullImagePath( image.url)"
                size="100px"
              />
              <font-awesome-icon
                v-else
                class="m-auto"
                :icon="['fas', 'upload']"
                size="lg"
              />

            </div>
          </transition>
        </template>
        <transition
          name="fade"
          mode="out-in"
        >
          <div
            v-if="image.id"
            class="d-flex flex-wrap mt-1"
          >
            <b-button
              variant="outline-danger"
              @click="image = {} , image.id=null,$refs.refInputEl.value=null"
            >
              <span class="d-none d-sm-inline max-w">{{ $t('Remove') }}</span>
            </b-button>
          </div>
        </transition>
      </b-media>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VxUpload',
  props: {
    value: {
      default: null,
      type: Number,
    },
  },
  data() {
    return {
      image: {},
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.$store.dispatch('upload/getImage', { id: val }).then(response => {
          this.image = response.data.upload
        })
      } else this.image = {}
    },
    image(val) {
      if (val) this.$emit('input', val.id)
      else this.$emit('input', null)
    },
  },
  created() {
    if (this.value) {
      this.$store.dispatch('upload/getImage', { id: this.value }).then(response => {
        this.image = response.data.upload
      })
    } else {
      this.image = {}
    }
  },
  methods: {
    uploadImage(event) {
      if (event.target.files[0]) {
        this.$store.dispatch('upload/uploadImage', event.target.files[0]).then(response => {
          this.image = response.data.Media
        })
      }
    },
  },

}
</script>
<style>
  .UploadImageButton {
    border-radius: 50% ;
    width: 100px;
    height: 100px;
  }
  .UploadImageButton:hover{
    cursor: pointer
  }
  .UploadImageBorder{
    border: 1px  dashed
  }
</style>
