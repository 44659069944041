<template>
  <div
    style="width: max-content"
  >
    <b-popover
      :target="'_popover'+_uid"
      triggers="hover"
      :variant="color"
      no-fade
    >
      <template #title>
        <slot name="title" />
      </template>
      <slot name="text" />
    </b-popover>
    <div :id="'_popover'+_uid">
      <slot />
    </div>
  </div>
</template>
<script>

export default {
  name: 'VxTooltip',
  props: {
    color: {
      default: 'primary',
      type: String,
    },
  },
}
</script>
