const state = {
  model: null,
  total: 0,
  current_page: 1,
  firstItem: 0,
  lastItem: 0,
  page: 1,
  take: 5,
  search: null,
  sort: null,
  sortType: null,
  filter: null,
}
export default state
