<template>
  <font-awesome-icon
    :icon="[pack, icon]"
    :color="IconColor"
    :size="size+'x'"
  />
</template>
<script>
export default {
  name: 'FaIcon',
  props: {
    pack: {
      default: 'fa',
      type: String,
    },
    icon: {
      default: null,
      type: String,
    },
    color: {
      default: null,
      type: String,
    },
    size: {
      default: '1',
      type: String,
    },
  },

  data: () => ({

  }),
  computed: {
    IconColor() {
      if (this.color === null) return `${this.color}`
      if (this.color.charAt(0) === '#' || this.color.substr(0, 3) === 'rgb') return `${this.color}`
      return `rgb(var(--vs-${this.color}))`
    },
  },
}
</script>
