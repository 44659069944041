// import axios from 'axios'
import Vue from 'vue'

const actions = {
  LoginAction({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('authorize/login', payload).then(response => {
        if (response.data.access_token) localStorage.setItem('access_token', response.data.access_token)
        commit('UPDATE_USER_INFO', response.data.user)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  SocialLoginAction(_, provider) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get(`authorize/${provider}/redirect`).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  SocialLoginCallback({ commit }, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.get(`authorize/${payload.provider}/callback`, {
        params: {
          code: payload.token,
        },
      }).then(response => {
        localStorage.setItem('access_token', response.data.access_token)
        commit('UPDATE_USER_INFO', response.data.user)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  Logout({ commit }, payload) {
    commit('LOGOUT', {})
    if (payload) {
      Vue.prototype.$router.push({
        name: 'login',
      })
    }
  },
  sendResetLink(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('authorize/send_reset_link', { email: payload.email }).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  checkCode(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('authorize/check_code', { code: payload.code, email: payload.email }).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  resetPassword(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('authorize/reset_password', {
        email: payload.email,
        password: payload.password,
        password_confirmation: payload.password_confirmation,
      }).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  UpdateAuth({ commit }) {
    Vue.prototype.$http.post('getAuth').then(response => {
      // localStorage.setItem('access_token', response.data.access_token)
      commit('UPDATE_USER_INFO', response.data.user)
    }).catch(() => {
      commit('LOGOUT', {})
    })
  },
  Register(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('authorize/register', payload).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  SignUp(_, UserData) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('authorize/signup', UserData).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  ChangePassword(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('home/auth/change_password', payload.password).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  CheckCode(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('authorize/check-code', payload).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
}
export default actions
