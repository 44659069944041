import Vue from 'vue'

const actions = {
  getPlans({ commit }) {
    Vue.prototype.$http.get('home/get_plans').then(response => {
      commit('SET_PLANS', response.data.plans)
    })
  },
  getPlan({ commit }, payload) {
    Vue.prototype.$http.get(`home/get_plan/${payload.id}`).then(response => {
      commit('SET_PLAN', response.data.plan)
    })
  },
  pay(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('home/pay', payload.plan).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getPosts({ commit }) {
    Vue.prototype.$http.get('home/get_posts').then(response => {
      commit('SET_POSTS', response.data.data)
      commit('SET_POSTS_PAGINATION', response.data.paginate)
    })
  },
  ChangePage({ commit }, payload) {
    commit('CHANGE_PAGE', payload.page)
    Vue.prototype.$http.get(`home/get_posts?page=${payload.page}`).then(response => {
      commit('SET_POSTS', response.data.data)
      commit('SET_POSTS_PAGINATION', response.data.paginate)
    })
  },
  getPost({ commit }, payload) {
    Vue.prototype.$http.get(`home/get_post/${payload.id}`).then(response => {
      commit('SET_POST', response.data.post)
    })
  },
  getCategories({ commit }) {
    Vue.prototype.$http.get('home/categories').then(response => {
      commit('SET_CATS_DATA', response.data.categories)
    })
  },
  GetTags({ commit, rootGetters, dispatch }) {
    Vue.prototype.$http.get(`home/tags${rootGetters['paginate/paginateUrl']}`).then(response => {
      commit('GET_TAGS_DATA', response.data.data)
      dispatch('paginate/SetPaginate', response.data.paginate, { root: true })
    })
  },
  storeComment(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('home/store_comment', payload).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  filterPosts({ commit }, FilterData) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('home/filter_posts', FilterData).then(response => {
        commit('SET_POSTS', response.data.data)
        commit('SET_POSTS_PAGINATION', response.data.paginate)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  contactUs(_, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('home/contact_us', payload).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  updatePostLikes({ commit }, likeCount) {
    commit('UPDATE_POST_LIKES', likeCount)
  },
  updateCommentLikes({ commit }, data) {
    commit('UPDATE_COMMENT_LIKES', data)
  },
  updateRepliesLikes({ commit }, data) {
    commit('UPDATE_REPLIES_LIKES', data)
  },
}
export default actions
