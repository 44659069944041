/* eslint-disable */
const mutations = {
  GET_CASHIER_TRANSACTIONS(state){
    state.cashierTransactions = []
    state.cashierTransactions.push({no:1,issueDate:'01/01/2020', category:'category1', description:'description1', income:'300', outcome:'600', balance:'500', beneficiary:'20'})
    state.cashierTransactions.push({no:2,issueDate:'02/02/2020', category:'category2', description:'description2', income:'400', outcome:'550', balance:'400', beneficiary:'15'})
  },
  SET_CASHIER_TRANSACTIONS(state, data){
    state.cashierTransactions = data
  },
  ACCOUNT_TRANSACTIONS(state,data){
    state.accountTransactions = []
    state.accountTransactions.push(data)
  },
  SET_USERS(state, users){
    state.users = users
  },
  SET_TRANSACTION_CATEGORIES(state, transactionCategories){
    state.transactionCategories = transactionCategories
  }

}
export default mutations
