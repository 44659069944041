export default [
  {
    path: '/acp/categories',
    name: 'Category',
    component: () => import('@/views/dashboard/category/list.vue'),
    meta: {
      role: 'SuperAdmin',
      authRequired: 'true',
      layout: 'vertical',
    },
  }, {
    path: '/acp/category/trashed',
    name: 'categories_trashed',
    component: () => import('@/views/dashboard/category/trashed.vue'),
    meta: {
      role: 'SuperAdmin',
      authRequired: 'true',
      layout: 'vertical',
    },
  },
]
