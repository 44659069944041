<template>
  <div class="this-is-pagination-Table-component">
    <vs-table
      ref="table"
      :data="data"
      :sst="true"
      :no-data-text="$t('No data Available')"
      pagination
      :max-items="max_items"
      :total="total"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-row
          class="paginate-header"
        >
          <vs-col
            vs-lg="3"
            vs-sm="12"
          >
            <h3>
              {{ $t(table_name) }}
            </h3>
          </vs-col>
          <vs-col
            vs-lg="5"
            vs-sm="12"
          />
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="flex-end"
            vs-lg="3"
            vs-sm="9"
            vs-xs="8"
          >
            <b-input-group
              class="input-group-merge"
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="search"
                :placeholder="$t('Search')"
                @keyup.enter="handleSearch()"
              />
            </b-input-group>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="flex-end"
            vs-lg="1"
            vs-sm="3"
            vs-xs="4"
            class="pl-1"
          >
            <vs-select
              v-model="take"
              class="selectExample"
            >
              <vs-select-item
                v-for="(item, index) in [5, 10, 20, 50]"
                :key="index"
                :value="item"
                :text="item"
              />
            </vs-select>
          </vs-col>
        </vs-row>
      </template>
      <slot :data="data" />
    </vs-table>
    <span class="select-label-placeholder">
      {{ firstItem }} - {{ lastItem }} of {{ total }}
    </span>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'VsPaginationTable',
  props: ['data', 'model_type', 'table_name'],
  data() {
    return {
      search: "",
    };
  },

  computed: {
    take: {
      get: function () {
        return this.$store.state.paginate.take
      },
      set: function (newValue) {
        this.$store.dispatch('paginate/ChangeTake', {'take': newValue})
      }
    },
    firstItem() {
      return this.$store.state.paginate.firstItem
    },
    lastItem() {
      return this.$store.state.paginate.lastItem
    },
    max_items() {
      return this.$store.state.paginate.take
    },
    total() {
      return this.$store.state.paginate.total
    }
  },
  methods: {

    handleSearch() {
      this.$store.dispatch('paginate/Search', {'search': this.search})
    },
    handleChangePage(val) {
      this.$store.dispatch('paginate/ChangePage', {'page': val})
    },
    handleSort(sort, sortType) {
      if (sortType) {
        this.$store.dispatch('paginate/Sort', {'sort': sort, 'sortType': sortType})
      } else {
        this.$store.dispatch('paginate/Sort', {'sort': null, 'sortType': null})
      }
    }
  },
  created() {
    this.$store.dispatch('paginate/SetPaginateDefault')
    this.$store.dispatch('paginate/SetModel', {model: this.model_type})
    this.$store.dispatch('paginate/GetData')
  }
};
</script>

