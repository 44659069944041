<template>
  <div>
    <div class="form-label-group">
      <b-form-textarea
        :id="'_label' + _uid"
        v-model="localValue"
        placeholder=" "
        :state="errorCheck()"
      />
      <label
        class="lp"
        :for="'_label' + _uid"
      >{{ label }}</label>

      <div v-if="errors">
        <b-alert
          show
          variant="danger"
          fade
        >
          <ul>
            <li
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ $t(error) }}
            </li>
          </ul>
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VxTextarea',
  props: {
    label: {
      default: '',
      type: String,
    },
    value: {
      default: null,
      type: String,
    },
    errors: {
      default: null,
      type: Array,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  created() {
  },
  methods: {
    errorCheck() {
      if (this.errors) return false
      return null
    },
  },
}
</script>

<style scoped>
  .lp {
    opacity: 0.5
  }
  .lp:focus {
    color:rgb(var(--vs-primary));
    opacity: 1;
  }
</style>
